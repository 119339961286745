import React, { useState, useEffect } from 'react';
import $ from 'jquery'
import FAQ from "../components/FAQ";


const AboutUsPage = ({ match, location, history }) => {

    return (
        <div class={"simple-content first_row"}>
            <h1 class={"title"}>F.A.Q</h1>
            <p className={"sub-title"}>You have a doubt?<br/> Your answer should be down there. </p>
            <div className={"description"}>
                <FAQ/>
            </div>
        </div>
    )
}

export default AboutUsPage;