import React, {Component, PropTypes,useState} from 'react';
import { TweenMax, TweenLite, gsap } from "gsap/all";
import { Transition,SwitchTransition } from "react-transition-group";

const FadeTransition = (props) => {
    let animationCallback;

    console.log(props.in);
    let callback = null;
    let prev_message_node = false;
    const [prevMessageNode,setPrevMessageNode] = useState(false)
    return (

        <Transition
            {...props}
            mountOnEnter
            //unmountOnExit
            addEndListener={(node,done ) => {

                console.log(prevMessageNode);
                // if transition animation
                if(prevMessageNode!==false){

                    /*console.log(prevMessageNode);
                    let prev_message_node = node.cloneNode(true);
                    setPrevMessageNode( prev_message_node );
                    node.parentNode.insertBefore(prev_message_node,node);
*/
                    console.log(prevMessageNode);
                    node.parentNode.insertBefore(prevMessageNode,node);

                    let tl = gsap.timeline();
                    // exit

                    tl.set(prevMessageNode,{
                        position:"absolute"
                    })
                    tl.fromTo(prevMessageNode, {
                        y: "0px", autoAlpha: 1
                    },{
                        y: "20px", autoAlpha: 0, duration: 1,
                    })
                    // enter

                    tl.fromTo(node, {
                            y: "-20px", autoAlpha: 0
                        }, {
                            y: "0px", duration: 1, autoAlpha: 1,
                            onComplete: function(){
                                prevMessageNode.remove();
                                setPrevMessageNode( node.cloneNode(true).classList.add('cloned') )
                                done();
                            }
                        }
                        ,-0.1);

                }else{

                    // if start animation
                    TweenMax.fromTo(node, 1, {
                        Y: -20,
                        autoAlpha: 0},{
                        Y: 0,
                        autoAlpha: 1,
                        onComplete: done
                    });
                    prev_message_node = node.cloneNode(true);
                    setPrevMessageNode( prev_message_node );
                    prev_message_node.classList.add("cloned-element");

                }
            }}
        >
            {state => (
                props.children
            )}
        </Transition>



)};
export default FadeTransition