import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import background_form from './../media/background_form_03.svg'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

class Testimonial extends React.Component {

    state = {
        Testimonials:[
            {
                avatar:"/testimonial_avatar/avatar_02.jpg",
                message:'I did a Flipbook for my wedding,\n' +
                    'it works !!! I’m still married ! <span class="emoji">🥳</span>',
                name:"Patrick",
                age:"42"
            },
            {
                avatar:"/testimonial_avatar/avatar_03.jpg",
                message:'I did several flipbooks of my kids first steps, ' +
                    'my screen-time on smartphone went down by 60%',
                name:"Paula",
                age:"34"
            },
            {
                avatar:"/testimonial_avatar/avatar_04.jpg",
                message:'After 6 month of intense flipping, I am now a master of Thumb wrestling',
                name:"Rocky",
                age:"19"
            },
            {
                avatar:"/testimonial_avatar/avatar_05.jpg",
                message:'100 % of ophthalmologists recommend Flipbooks over Smartphones',
                name:"Dr Eyez",
                age:"67"
            },
            {
                avatar:"/testimonial_avatar/avatar_06.jpg",
                message:'Do you feel me flipping my Flip Flip in my Flip Flops?',
                name:"MC Furtz",
                age:"24"
            },

        ]

    }

    render() {

        function NextButton({ onClick }) {
            return <div onClick={onClick} className="custom-slick-arrow custom-slick-prev" style={{ left: 0 }}>
                <span></span>
                <span></span>
            </div>;
        }

        function PrevButton({ onClick }) {
            return <div onClick={onClick} className="custom-slick-arrow custom-slick-next" style={{ right: 0 }}>
                <span></span>
                <span></span>
            </div>;
        }

        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            nextArrow: <PrevButton/>,
            prevArrow: <NextButton/>,
            responsive: [{
                breakpoint: 960,
                settings: {
                    dots: true,
                }
            }]
        };

        const faq_items = this.state.Testimonials.map((item, i) => {
            const age = (item.age) && <React.Fragment>, <span className={"age"}>{item.age} years old</span></React.Fragment>;
            const info = (item.name) && <span className={"info"}>{item.name}{age}</span>;
            return (
                <div key={i+"-testimonial-item"} className={"box-container"} >
                    <div className={"box"}>
                        <div className={"avatar"}>
                            <img src={item.avatar} />
                        </div>
                        <div className={"content"}>
                            <p className={"message"} dangerouslySetInnerHTML={{__html: item.message}}></p>
                            {info}
                        </div>
                    </div>
                </div>
            );
        });



        return (
            <Box className={"section-testimonial primary-container"}>
                    <Box  p={2}  textAlign="center" p={2} className={"secondary-container"}>
                        <Box>
                            <Slider className={"custom-slider testimonial-slider"} {...settings}>
                                {faq_items}
                            </Slider>
                        </Box>

                    </Box>
            </Box>
        );
    }
}

export default Testimonial;
