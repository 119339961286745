import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Player, BigPlayButton, ControlBar, PlayToggle } from 'video-react';
import "./../../node_modules/video-react/dist/video-react.css"; // import css
import { useLocation } from 'react-router-dom'
import Logo from './../components/Logo';
import { Link } from "react-router-dom";


const FLP_S3_URL = "https://oinkoink-test.s3.eu-west-3.amazonaws.com/output-video/"

const useStyles = makeStyles({
  card: {
      maxWidth: 345,
  },
  media: {
      height: 140,
  },
  button: {
      width:"100%",
      justifyContent: 'center',
      alignItems: 'center'
  }
});



export default function PreviewPage({match}) {

    function componentDidMount() { document.title = 'FlipFlip'; };

    let location = useLocation();
    console.log(location);
    console.log("PreviewPage Id: "+location.state.id);
    console.log("PreviewPage Title: "+location.state.title);

       return(
            <div>
                <Logo/>
                <Card> 
                   <CardContent>
                   {/*<h1>{match.params.id}</h1>*/}
                   <Player
                     playsInline
                     src={FLP_S3_URL+match.params.id+".mov"}
                     autoplay={true}
                   >
                        <ControlBar disableCompletely={true} />
                      <BigPlayButton position="center" />
                   </Player>
                   </CardContent>
                </Card>
            <div style={{align:"center", padding: "10px"}}>
                <Link to={{
                    pathname: "/order/"+match.params.id,
                    state: {id: location.state.id, title: location.state.title}
                }}>

            <Fab variant="extended" style={{
                width: '70%',
                background: '#142033',
                color: 'white',
                }}>
            Order
            </Fab>
                </Link>
            </div>
            </div>

       );
};
