import React, { Component,useState, useEffect, useRef } from 'react'
import Grid from '@material-ui/core/Grid';
import { ReactComponent as ArrowRight } from '../media/arrow_right.svg'
import {Link, Route, Switch} from "react-router-dom";
import FlipCascade from './../media/FlipCascade.jpg';
import FAQ from './../components/FAQ';
import Tips from './../components/Tips';
import Testimonial from './../components/Testimonial';
import background_form from "../media/background_form_07.svg";
import VideoAutoplay from "../components/VideoAutoplay";
import video_flipflip from "../media/flipflip-video_480p.mp4";
import CustomHelmet from "../components/CustomHelmet";

const MainPage = () => {


        return (
            <React.Fragment >

                <CustomHelmet pageTitle={"FlipFlip - Turn your video into a fun and impressive Flipbook"}
                              pageDescription={"FlipFlip are beautiful printed Flipbooks from your videos. The best gift for yourself or your loved ones. "}
                              pageImage={"${baseURL}/presentation.jpg"}
                />

                <Link className={"button btn-sticky"} to="/start">Let's Start</Link>
                <div className={"first_row"}>
                    <Grid container spacing={0}>
                        <Grid item md={6} xs={12}>
                            {/*** Video ***/}
                            <div className={"player-video"}>
                                <VideoAutoplay src={video_flipflip} />
                            </div>
                        </Grid>

                        <Grid item md={6} xs={12} >
                            {/*** Presentation ***/}
                            <div className={"section section-presentation primary-container"}>
                                <div className={"secondary-container"} >
                                    <h2>
                                        Your best moments
                                        <div>FOREVER</div>
                                    </h2>
                                    <div className={"description"}>
                                        <p>
                                            <b>Turn your videos into a fun and impressive Flipbook.</b><br/>
                                            Your original gift, invitation, or announcement,<br/> for only <span style={{color:"#FF6767"}}>12.99 euros</span>.<br/>
                                            FlipFlips are printed on eco-friendly premium paper, allowing you to cherish the best moments of your life forever.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>

                <Grid container spacing={0} className={"second-row"}>
                    <Grid item xs={12} md={6}>
                        <div className={'image-deco-first'}>
                            <img src={FlipCascade} />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {/*** How it Works? ***/}
                        <div className={"section section-how-it-work primary-container"} >
                            <div className={"secondary-container"} >
                                <h2>
                                    How to create your Flipbook?
                                </h2>
                                <ol className={"bullet-number"}>
                                    <li><b>Upload</b> your vidéo</li>
                                    <li><b>Write</b> your title</li>
                                    <li><b>Place</b> your order with flipflip’s<br/>
                                        secured payment gateway</li>
                                    <li><b>Receive</b> your order and start to flip !</li>
                                </ol>
                                <div className={"highlight-link-container"}>
                                        <Link to="/start" >Let's start <ArrowRight/></Link>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>

                {/*** Tips ***/}
                <div className={"section-tips primary-container"}>
                    <div className={"secondary-container"}>
                        <Grid container direction="row" justify="center" alignItems="center" spacing={0}>
                            <Grid item md={6}>
                                <h2 >
                                    Our<span> tips</span> !
                                </h2>

                                <div className={"highlight-link-container"}>
                                    <Link to={"/tips"} >More Tips<ArrowRight /></Link>
                                </div>
                            </Grid>
                            <Grid item md={6}>
                                <Tips/>
                            </Grid>
                        </Grid>

                    </div>

                    <img className={"background-container"} src={background_form} />
                </div>


                {/*** How it Works? ***/}
                <Testimonial></Testimonial>

                {/*** FAQ ***/}
                <div className={"section-faq primary-container"}>
                    <div className={"secondary-container"}>
                        <Grid container direction="row" justify="center" alignItems="center" spacing={0}>
                            <Grid item xs={12} md={6}>
                                <h2>
                                    Frequently<br/>
                                    asked<br/>
                                    <div> questions</div>
                                </h2>

                                <div className={"highlight-link-container"}>
                                    <Link to={"/faq"} >More<ArrowRight /></Link>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FAQ qty_desktop={5} qty_mobile={3} />
                            </Grid>
                        </Grid>
                    </div>
                </div>

            </React.Fragment>

        );

}
export default MainPage;
