import React, {Component,useEffect} from 'react'
import './App.scss';
import MainPage from './pages/MainPage'

import LoadPage from './pages/LoadPage'
import PreviewPage from './pages/PreviewPage'
import OrderPage from './pages/OrderPage'
import SuccessPage from './pages/SuccessPage'
import TermsAndConditionsPage from './pages/TermsAndConditionsPage'
import ImprintPage from './pages/ImprintPage'
import {Switch, Route, Link,useLocation} from "react-router-dom"
import useSticky from "./hooks/useSticky.js"
import { ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import theme from './theme'
import CreatePage from "./pages/CreatePage";
import AboutUsPage from "./pages/AboutUsPage";
import FaqPage from "./pages/FaqPage";
import Container from "@material-ui/core/Container";
import Header from "./components/Header";
import DesktopMenu from "./components/DesktopMenu";
import ScrollToTop from "./components/ScrollToTop";
import Reinsurance from "./components/Reinsurance";
import Footer from "./components/Footer";
import background_presentation_circle from "./media/background_form_01.svg";
import background_presentation_desktop_svg from "./media/background_form_04.svg";
import BackButton from "./components/BackButton";
//import "../node_modules/video-react/dist/video-react.css"; // import css
import { useMediaQuery } from 'react-responsive'
import TipsPage from "./pages/TipsPage";
import FadeTransition from "./components/AnimationTransistion";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import CustomHelmet from './components/CustomHelmet';

function App(){
    let location = useLocation();
    let currentPage = "";
    const isDesktop = useMediaQuery({
        query: '(min-width: 960px)'
    })

    switch (location.pathname.split("/")[1]) {
        case "":
            currentPage = "page-home";
            break;
        default:
            currentPage = "page-"+location.pathname.split("/")[1].toLowerCase();
            break;
    }


    useEffect(() => {
        const script = document.createElement("script");

        script.async = true;
        script.src = "https://js.stripe.com/v3/";
        document.head.appendChild(script);
        document.body.appendChild(script);
    }, [])

    const { isSticky } = useSticky();
    const bodyClass = isSticky ? "stick" : "";

    return (

        <HelmetProvider>
            <CustomHelmet pageTitle={"FlipFlip - Turn your video into a fun and impressive Flipbook"}
                          pageDescription={"FlipFlip are beautiful printed Flipbooks from your videos. The best gift for yourself or your loved ones. "}
                          pageImage={"/presentation.jpg"} />

            <CssBaseline />
            <div className={currentPage+" "+bodyClass+" "}>
                {/*** Header ***/}
                <Header/>
                <div class={"page-content"}>
                    {isDesktop&& (<BackButton/>)}


                    <div className={"first-top-bg"}>
                        <img className={"bg-01"} src={background_presentation_circle}/>
                        <img className={"bg-02"} src={background_presentation_desktop_svg}/>
                        <div className={"background-left"}></div>
                        <div className={"background-right"}></div>
                    </div>

                    <DesktopMenu/>

                    <ScrollToTop >  
                        <Switch>

                            <Route path="/success/" component={SuccessPage}/>
                            <Route path="/order/:id" component={OrderPage}/>
                            <Route path="/preview/:id" component={PreviewPage}/>
                            <Route path="/load/:id" component={LoadPage}/>
                            <Route path="/imprint/" component={ImprintPage}/>
                            <Route path="/terms/" component={TermsAndConditionsPage}/>
                            <Route path="/start" component={CreatePage}/>
                            <Route path="/imprint" component={ImprintPage}/>
                            <Route path="/about-us" component={AboutUsPage}/>
                            <Route path="/tips" component={TipsPage}/>
                            <Route path="/faq" component={FaqPage}/>
                            <Route path="/" component={MainPage}/>
                        </Switch>
                    </ScrollToTop>
                </div>

                {/*** Footer ***/}
                <Footer></Footer>
            </div>
        </HelmetProvider>
    )
}

export default App;