import React, { useState, useEffect } from 'react';
import $ from 'jquery'
import CustomHelmet from "../components/CustomHelmet";
import {HelmetProvider} from "react-helmet-async";

const AboutUsPage = ({ match, location, history }) => {

    return (
        <div class={"simple-content"}>
            <CustomHelmet pageTitle={"FlipFlip - About us"}
                          pageDescription={"FlipFlip are beautiful printed Flipbooks from your videos. The best gift for yourself or your loved ones. "}
                          pageImage={"/presentation.jpg"} />

            <h1 class={"title"}>About us</h1>
            <p className={"sub-title"}></p>
            <div className={"description"}>
                <p>
                    Phil 🧔🏼and Alexis👨🏻‍🔬 thought about building a 🚀 to travel to the🌙
                </p>
                <p>
                    Both of them love watching 🎥 , eating 🍿<br/>
                    But how could they watch a 🎥  on the 🌙 , if you cannot charge your 📱 there?
                </p>
                <p>
                    Phil 🧔🏼and Alexis👨🏻‍🔬 decided to build a Flip Flip Machine instead
                </p>
                <p>
                    They can now enjoy their favorite 🎥 , flipping their 📚<br/>
                    No more excuses to build the 🚀 now<br/>
                    All of this happened in Strasbourg, Alsace, 🇫🇷
                </p>
            </div>
        </div>
    )
}

export default AboutUsPage;