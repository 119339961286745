import React, {Component, useEffect, useState} from 'react'
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import Box from '@material-ui/core/Box';
import { Grid } from '@material-ui/core';

import Amex from './../media/FooterLogo/Amex.svg'
import ApplePay from './../media/FooterLogo/ApplePay.svg'
import Bundespost from './../media/FooterLogo/Bundespost.svg'
import FSC from './../media/FooterLogo/FSC.svg'
import Maestro from './../media/FooterLogo/Maestro.svg'
import Mastercard from './../media/FooterLogo/Mastercard.svg'
import Stripe from './../media/FooterLogo/Stripe.svg'
import Visa from './../media/FooterLogo/Visa.svg'
import Reinsurance from "./Reinsurance";
import {Switch, Route, Link,useLocation} from "react-router-dom"
import {ReactComponent as BackgroundSvg} from './../media/background_form_02.svg'


function Copyright() {
    return (
        <Box textAlign="right">
            <Typography variant="body2" color="textSecondary">
                {'©'}{new Date().getFullYear()}{' '}
                <Link color="inherit" href="https://flipflip.io/">
                    FlipFlip
      </Link>
                {', '}All rights reserved.
        </Typography>
        </Box>

    );
}





export default function Footer() {
    let [displayFooter,setDisplayFooter] = useState(true);
    let location = useLocation();
    useEffect(() => {
        console.log(location.pathname.split("/")[1]);
        switch (location.pathname.split("/")[1]) {
            case "load":
            case "order":
            case "start":
                setDisplayFooter( false )
                break;
            default:
                setDisplayFooter( true )
                break;
        }

    }, [location]);

    let footer = (
        <div className={"footer"}>

                <p className={'copyright'}>©2020 FlipFlip, All rights reserved.</p>
                <div className={"footer-group-top"} >
                    <div className={"footer-top"}>
                        <div className={"picto-list picto-shipping-list"}>
                                <span className={"picto-item"}>
                                    <img src={Bundespost} alt="Bundespost" />
                                </span>
                            <span className={"picto-item"}>
                                    <img src={FSC} alt="FSC" />
                                </span>
                        </div>
                    </div>
                    <div className={"footer-mid"}>
                        <div className={"picto-list picto-paiement-list"}>
                                <span className={"picto-item"}>
                                    <img src={Amex} alt="Amex" />
                                </span>
                            <span className={"picto-item"}>
                                    <img src={ApplePay} alt="ApplePay" />
                                </span>
                            <span className={"picto-item"}>
                                    <img src={Maestro} alt="Maestro" />
                                </span>
                            <span className={"picto-item"}>
                                    <img src={Mastercard} alt="Mastercard" />
                                </span>
                            <span className={"picto-item"}>
                                    <img src={Visa} alt="Visa" />
                                </span>
                        </div>
                        <div className={"picto-list picto-paiement-list-2"} >
                                <span className={"picto-item"}>
                                    <img src={Stripe} alt="Stripe" />
                                </span>
                        </div>
                    </div>
                </div>
                <div className={"footer-bottom"}>
                    <ul id={"footer-menu"} >
                        <li>
                            <Link to={"/terms"} >Terms and Conditions</Link>
                        </li>
                        <li>
                            <Link to={"/imprint"} >Imprint</Link>
                        </li>
                        <li>
                            <Link to={"/Faq"}>FAQ</Link>
                        </li>
                        <li>
                            <Link to={"/about-us"}>About us</Link>
                        </li>
                        <li>
                            <a href={"mailto:contact@flipflip.io"}>Contact</a>
                        </li>
                    </ul>
                </div>
        </div>
    );

    return (
        <React.Fragment>
            {displayFooter&&(
            <footer className={"primary-container"}>
                <div className={"secondary-container"}>
                    {/*** Reinsurance ***/}
                    <Reinsurance/>
                    {/** Footer **/}
                    {footer}
                </div>
                <BackgroundSvg className={"background-container"} />
            </footer>
            )}
        </React.Fragment>
    );
}
