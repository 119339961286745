import { createMuiTheme }  from '@material-ui/core/styles'

const theme = createMuiTheme({
    body:{
        fontFamilly: 'lato'
    },
  palette: {
    primary: {
        500: '#0000ff',
        main: '#FFF4F4'},
  },
  overrides: {
    /* AppBar */
    MuiAppBar:{
        root: {
            background: '#FF00FF'
        },
        colorDefault: {
            background: '#FF0000'
        },
        colorPrimary: {
            background: '#FF0000'
        },
    },
    /* Button */
    MuiButton: {
      root: {
        borderRadius: '30px',
        color: 'white', '&:hover': {
              backgroundColor: '#FF00FF'
        },
          padding: '0 30px',
          boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      },
      containedPrimary: {
              backgroundColor: '#FF6767',
              color: "#FFFFFF",
              textTransform: "capitalize",

      },
      large:{

          padding: "80px",
          margin: "80px"
      }
    },
    /* Typography */
    MuiTypography: {
          h4: {
            color: "#000033",
            fontWeight: "900"
          },
          subtitle1: {
            color: "#000033",
            paddingTop: "20px"
          }
    },
  }
});

export default theme
