import React, { Component }  from 'react';

import Uploader from './../components/Uploader';
import TitleInputField from './../components/TitleInputField';
import background_form_03 from './../media/background_form_03.svg';
import background_form_04 from './../media/background_form_04.svg';
import icon_slow_motion from './../media/icon-slow-motion.svg';
import Reinsurance from "../components/Reinsurance";
import Footer from "../components/Footer";
import BestPractices from "../components/BestPractices";
import Box from "@material-ui/core/Box";

import background_form from "../media/background_form_03.svg";
import Accordion from "../components/Accordion";
import FadeTransition from "../components/AnimationTransistion";



class CreatePage extends Component {
componentDidMount() { document.title = 'FlipFlip'; };

    constructor(props){
        super(props);
        this.state = {
                showTitleInputField: false,
                isMount:null,
                id: "",
        };
        this.myRef = React.createRef();
        this.handleUploadClick = this.handleUploadClick.bind(this);
        this.passId = this.passId.bind(this);
     }

    handleUploadClick(bool) {
        this.setState( state => ({showTitleInputField:bool}));
    };

    passId(id) {
        console.log("In callback passId()");
        console.log("Passed ID: "+id);
        this.setState( state => ({id:id}));
    };

    componentDidMount() {
       // this.setState( state => ({isMount:true}));
    }

    componentWillUnmount() {
    //    alert("will unmout")
       // this.setState( state => ({isMount:false}));
    }

    render() {
       return(
           <React.Fragment>
                <div className={"section-upload primary-container"}>
                    <div className={"secondary-container"}>
                        <Uploader showTextInputField={this.handleUploadClick} id={this.passId} style={{textAlign:"center"}}/>
                        { this.state.showTitleInputField ?  (
                            <React.Fragment>
                                <TitleInputField id={this.state.id}/>
                            </React.Fragment>
                        ): (null)
                        }
                    </div>
                    {/*<div className={"background-container"}>
                        <img  src={background_form_04} />
                        <div className={"background-left"}></div>
                        <div className={"background-right"}></div>
                    </div>*/}
                </div>

                {/***Best Practices ***/}
                {/*<BestPractices></BestPractices>*/}
                {/*
                <div className={"section-tips primary-container"}>
                    <div className={"secondary-container"}>
                        <div className={"picto-box"}>
                            <img src={icon_slow_motion} alt={"icon tips slow motion"}/>
                            <p className={"textRed"}>
                                Slow motion videos<br/>
                                is a great idea
                            </p>
                        </div>
                    </div>
                    <img className={"background-container"} src={background_form_03} />
                </div>
                */}
           </React.Fragment>
       );
    }
}
export default CreatePage;
