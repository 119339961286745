import React, { useState, useEffect } from 'react';
import $ from 'jquery'

const TermsAndConditionsPage = ({ match, location, history }) => {

    return (
        <div class={"simple-content"}>
            <h1 class={"title"}>Terms and Conditions</h1>
            <p className={"sub-title"}></p>
            <div className={"description"}>
                <h2 class={"count-this"}>Validity</h2>
                <p>Flip Flip SARL 50 Rue Custine 75018 Paris (hereinafter referred to as "Flip Flip") and the Purchaser are the contractors under the following terms and conditions.
                For the business connection between "Flip Flip" and the buyer, the subsequent terms and conditions are valid in their composure from the moment of the order.
                    Flip Flip doesn´t accept deviant conditions of the buyer, unless Flip Flip has accepted an assertive written application earlier.</p>
                <h2 class={"count-this"} >Prices</h2>
                <p>All listed prices are retail prices inclusive of the legal value added tax,
                    which is valid in each case (nowadays 20 percent) without shipping costs.
                    All offered promotions and special offer prices only apply to retail customers.
                    Commercial customers are excluded from these actions.
                    Costs for shipping (forwarding expenses) are charged according to our installation separately.
                    All listed prices, also for packaging and dispatch, count only at the time of the order.
                    With updates on the Internet pages of Flip Flip all previous prices and other information about goods become invalid.
                    The version valid at the time of the order is decisive in each case.</p>
                <h2 class={"count-this"} >Payment Terms</h2>
                <p>The customer can choose his payment method at the time of order.
                    Costs that are incurred due to insufficient funds or faulty provided bank data,
                    especially the costs of back charge and other miscellaneous are to be reimbursed by the customer.
                    If the purchaser in arrears, Flip Flip has the right to demand arrears charges of 5% above the announced base interest rate per year by the European Central Bank and miscellaneous bank processing fees.
                    In the event of a proven higher arrear damage caused by delay has occurred,
                    Flip Flip has the right, to assert claim to said damage. The customer bears the costs of payment, particularly transferred from abroad.</p>
                <h2 class={"count-this"} >Right of withdrawal</h2>
                <p>Customers do not have the right to cancel orders of products customized to the customer's specifications or tailored specifically to the personal needs of the customer.
                    For this reason, your screen print order of a customized print cannot be canceled,
                    as expressly stated here. In the case of prepayment,
                    if payment has not appeared in the account listed in the order confirmation within seven days,
                    then Flip Flip has the right to cancel the order and to withdraw from the contract.</p>
                <h2 class={"count-this"} >Terms of Delivery</h2>
                <p>The delivery is done by sending the purchased items to the customer to the address provided.
                    After receipt of payment your order will be prepared for shipment within 1-3 working days.
                    Delivery time will be 3 - 5 working days in average.
                    Each delivery is subject to the provison that Flip Flip timely and properly supplied.
                    If a product ordered by the customer is found to be unavailable, an equivalent of the price of the ordered items will be provided.
                    Flip Flip will notify the customer immediately about the unavailability and the order will be cancelled while refunding the payment at the same time.
                    Flip Flip is entitled to make partial deliveries. Delayed deliveries of Flip Flip do not entitle the buyer to compensation claims,
                    unless Flip Flip is of negligence or intent to load. Further claims remain reserved for the customer.
                    Delays in delivery based on the grounds that Flip Flip is not responsible for (force majeure, fault of others, etc.),
                    the deadline will be extended and the customer shall be informed immediately.
                    If prolonged, the causes of delays longer than four weeks after the conclusion of the contract that each party is entitled to withdraw from the contract.
                    If the customer is not encountered at home upon the delivery of the package,
                    and the packet is not picked up from the post office within 7 days by the customer or the customer refuses the acceptance of the package,
                    Flip Flip has the right to cancel the order and cancel the contract. Forwarding charges include packing and shipping for each picture.</p>
                <h2 class={"count-this"} >Warranty</h2>
                <p>The defect liability terms in accordance with the law apply.
                    The customer is not entitled to transfer a claim. If there are no other results,
                    further titles of the customer - for whatever reasons - are excluded.
                    Flip Flip holds no responsibility for faults that arise on items to be delivered; especially Flip Flip takes no responsibility for lost profit or other financial loss of the purchaser.
                    As far as the contractual liability of Flip Flip is excluded or restricted, this applies to personal liability of jobholders,
                    substitutes and auxiliary personnel. Apparent faults have to be reported in written immediately. However this should be implemented within two weeks from the delivery.
                    The reports that are implemented after the fortnight period will be discarded.
                    Inadequacy of quality will not be an issue as long as the delivered quality is corresponding to technical standards of digital Photo development and editing.
                    Since the technical variations in colors of image and original can't be avoided,
                    it will not be considered a fault related to the quality. Loss of quality: it wont be a fault with quality had the original itself is of less quality. (e.g. resolution of original image).
                    A specific characteristic of deliverables is not agreed.
                    Limitation of liability will not be valid if it has been an intentional effort,
                    caused by careless treatment . Warranty does not apply if the purchaser is found to be with fallacies and claims regulated by law.
                    Typically, if Flip Flip violates an essential contractual obligation,
                    liability to pay damages is restricted to the incidental damage. If the supplementary performance has been carried by means of additional supply,
                    the purchaser is obliged to return the original items within 30 days on account of Flip Flip. Return consignment of faulty goods has to be fulfilled according to statutory provisions.
                    Flip Flip reserves the right for claiming compensation according to legal matters. Limitation period is 24 months per delivery.</p>
                <h2 class={"count-this"} >Retention of title</h2>
                <p>The products ordered remain the property of Flip Flip until payment is completed. No resale, leasing, pledging,
                    transfer of title for security purposes, conversion,
                    other disposition or transformation is permissible before conveyance of title without the explicit consent of Flip Flip.</p>
                <h2 class={"count-this"} >Prohibition of offset, rights to withhold</h2>
                <p>The customer is not entitled to offset claims of Flip Flip for payment with his own claims, unless the claims of the customer are established beyond dispute or are of legal force.
                    The customer is not entitled to oppose claims of Flip Flip for payment with rights to withhold – even for notification of deficiencies – unless they result from the same contractual relationship.</p>
                <h2 class={"count-this"} >Copyrights</h2>
                <p>The client is solely responsible for the contents of transferred image data. We ass
                    ume that the client possesses the necessary copyrights, trademark rights or other rights for all works,
                    files and images that are transferred. In case of infringement of those rights,
                    the client is obligated to indemnify Flip Flip from any claims that might be issued by third parties.
                    This is strictly ensured by liability assumption versus the claimant. If the claimant does not approve the liability assumption the client will indemnify Flip Flip from all claims.
                    In this case the client will support Flip Flip in the defense of such claims. The client bears all incidental expenses (e.g. court and lawyer costs, fines, etc.).</p>
                <h2 class={"count-this"} >Responsibility of Penology, right to withhold performance</h2>
                <p>The customer is liable for the lawfulness of the content. He assures that the contents of the transferred image files don't offend the penal law,
                    with the placement of his order. Furthermore Flip Flip has no obligation to deliver services which have the consequence of statutory violations.
                    Flip Flip will report an offence to the police if contents of files sent by the customer violate the penal law.
                    Simultaneously Flip Flip has the right to deny any service and to withdraw from the contract.
                    Flip Flip is allowed but not obliged to examine the contents that are uploaded to the Web album as far as their rightfulness is concerned.
                    As long as contents violate applicable laws, Flip Flip has the right to delete contents without prior notice.
                    In case of complaints or objections of third parties against the information of the customer – regardless of the legal ground – Flip Flip will immediately delete the contents.
                    A claim against Flip Flip due to the blocking of contents after a complaint or objection by a third party is excluded,
                    if Flip Flip is said to have made an unjustified deletion deliberately without inspection.</p>
                <h2 class={"count-this"} >Choice of forum clause</h2>
                <p>Should a provision of this agreement be invalid or become invalid or should this agreement contain an omission,
                    then the legal effect of the other provision shall not thereby be affected.
                    Instead of the invalid provision a valid provision is deemed to have been agreed upon which comes closest to what the parties intended;
                    the same applies in the case of an omission.
                    Customer hereby agrees that Cologne is to be determined as the exclusive forum for all legal and contractual disputes resulting
                    directly or indirectly from the contractual relationship if the purchaser is a legal entity formed under German law as tradesman,
                    German Commercial Code. Cologne is also the exclusive forum for any disputes resulting directly or indirectly from the contractual relationship
                    if the customer is determined not to be subject to personal jurisdiction in Federal Republic of Germany. Furthermore,
                    Cologne is determined to be the exclusive forum for any disputes if a customer moves his principal place of residence,
                    outside of the Federal Republic of Germany or his principal place of residence is determined to be unknown at the point of commencing legal action.</p>
                <h2 class={"count-this"} >Governing Law</h2>
                    <p>The closure and liquidation of all contracts shall be governed by French law. The application of the CISG is excluded.</p>
            </div>
        </div>
    )
}

export default TermsAndConditionsPage;