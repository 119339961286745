import React, { Component } from 'react';
import PropTypes from 'prop-types';

class AccordionSection extends Component {
    static propTypes = {
        children: PropTypes.instanceOf(Object).isRequired,
        isOpen: PropTypes.bool.isRequired,
        label: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
    };

    onClick = () => {
        this.props.onClick(this.props.label);
    };

    render() {
        const {
            onClick,
            props: { isOpen, label },
        } = this;

        return (
            <div className={"accordion-item "+(isOpen?"is-open":"")} onClick={onClick}>
                <div className={"accordion-item-label"}>{label}</div>
                <div className={"accordion-button"}>
                    <div className={"accordion-button-box"}>
                        <div className={"accordion-button-inner"}></div>
                    </div>
                </div>

                {isOpen && (
                  <div className={"accordion-item-content"} >
                    {this.props.children}
                  </div>
                )}
      </div>
    );
  }
}

export default AccordionSection;