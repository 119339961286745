import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import logo from  './../media/header.svg';


function Logo() {
	return (
        <Grid container justify="center" alignItems="center" >
            <Grid item justifyContent="center" > 
                <Box m={1}>
                <img src={logo} align="center" width="350px" alt="FlipFlip logo"/>
                </Box>
            </Grid>
        </Grid>
	)

} 
export default Logo;
