import React from "react";
import {Helmet} from "react-helmet";


const CustomHelmet = (props) =>{

    return (
        <Helmet>
            <title>{props.pageTitle}</title>
            <meta name="description" content={props.pageDescription} />
            <meta property="og:title" content={props.pageTitle} />
            <meta property="og:description" content={props.pageDescription} />
            <meta property="og:image" content={props.pageImage} />
            <meta name="twitter:title" content={props.pageTitle} />
            <meta name="twitter:description" content={props.pageDescription} />
            <meta name="twitter:image" content={props.pageImage} />
        </Helmet>
    )
}

export default CustomHelmet;
