import React, { Component }  from 'react'
import Fab from '@material-ui/core/Fab';
import Logo from './../components/Logo';
import image_main_bg from './../media/createpage-bg-all.svg';
import { Link } from "react-router-dom";

var styleFab = {
    position: "fixed",
    bottom: 6,
    width: '70%',
    background: '#142033',
    color: 'white',
    left:"15%",
    textDecoration: "none",
};

var containerStyle = {
    width: "100%",
    backgroundImage: `url(${image_main_bg})`,
    backgroundRepeat: `no-repeat`,
    backgroundPosition: `center`,

    textAlign: "center",
    padding: "0",
    color: "#142033",
    fontWeight: "bold",
    fontFamily: "Roboto",
}

class SuccessPage extends Component {

componentDidMount() { document.title = 'FlipFlip'; };

    render() {
       return(

           <div className={"simple-content"}>
               <h1 className={"title"}>Awesome!</h1>
               <p className={"sub-title"}>:)</p>
               <div className={"description"}>
                   <p>
                       Your &quot;Best Moment&quot;<br/>
                       will be shipped shortly
                   </p>
               </div>
               <Link className={"button"} to="/">Another one?</Link>
           </div>

    );
    }
}
export default SuccessPage;
