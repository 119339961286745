import React, {Component, useEffect, useState} from 'react'
import {Switch, Route, Link,useLocation} from "react-router-dom"

const BackButton = () => {

    const [backLinkPath, setBackLinkPath] = useState(false);
    let location = useLocation();
    useEffect(() => {
        switch (location.pathname.split("/")[1]) {
            case "":
                setBackLinkPath( false )
                break;
            case "start":
                setBackLinkPath("/");
                break;
            case "load":
                setBackLinkPath("/start");
                break;
            case "order":

                setBackLinkPath("/load/"+location.pathname.split("/")[2]);
                break;
            default:
                setBackLinkPath( false )
                break;
        }

    }, [location]);

    return (
        (backLinkPath)&&(
                <Link className="back-button" to={{pathname:backLinkPath,state:location.state}} >
                    <div className="back-button-inner"></div>
                </Link>
            )

    )
}

export default BackButton;