import React, { Component } from 'react';
import axios from 'axios';
import Dropzone from './../components/Dropzone';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as UploadArrowSvg } from '../media/upload_arrow.svg'
import { ReactComponent as CheckSvg } from '../media/check.svg'
import LinearProgress from '@material-ui/core/LinearProgress';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { TweenMax, gsap } from "gsap/all";
import { Transition } from "react-transition-group";
import FadeTransition from "./AnimationTransistion";

const S3_ENDPOINT = "https://hgeo9o8jl5.execute-api.eu-west-3.amazonaws.com/test0/signedurl"
const API_PREVIEW_ENDPOINT = "https://hgeo9o8jl5.execute-api.eu-west-3.amazonaws.com/test0/preview"
const FLP_API_PREVIEW_URL = "https://hgeo9o8jl5.execute-api.eu-west-3.amazonaws.com/test0/render/"

class Uploader extends Component {

    constructor(props) {
        super(props);
        this.prev_message_node  = false;
        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleUpload= this.handleUpload.bind(this);
        this.circleProgressRef = React.createRef();
    }

    state = {
        selectedFile: null,                  
        loaded: 0,
        uploadProgress: 0,
        message: "",
        defaultmessage: "",
        uploading: false,
        showFilePicker: true,
        showDropzone: true,
        id: "",
    };

    // Blender on EC2
    launchRender(id,title){
        let ec2url = FLP_API_PREVIEW_URL+id;
        console.log("[START] PUT EC2: "+id);
        axios.put(ec2url).then(response => {console.log(response.data)});
        console.log("[DONE] PUT EC2: "+id);
    }

    async previewAsync(id,title) {
      try {
        let url = API_PREVIEW_ENDPOINT;
        let data = {'id':id}
        const response = await axios.put(url, data);
        console.log(response);
      } catch (error) {
        console.error(error);
      }
    }



    launchPreview(id,title){
        console.log("LAUNCH PREVIEW");
        console.log("ID: "+id);

        let url = API_PREVIEW_ENDPOINT;
        console.log("[START] PUT-01: "+id);
        console.log(url);
        let data = {'id':id}
        console.log(data);
        // PUT
        axios.put(url,data).then(
            response => {
                console.log("[DONE] PUT-01: "+id);
                console.log(response.status);
                console.log(response.data);
                this.launchRender(id,title);
            }
        ).catch(
            error => {
                console.log(error);
                console.log("[ERROR] PUT-01: "+id);
            }
        ).then( function () {
            console.log("[START] launchRender(): "+id);
            let ec2url = FLP_API_PREVIEW_URL+id;
            console.log("[START] PUT-02: "+id);
            axios.put(ec2url).then(response => {console.log(response.data)});
            console.log("[DONE] PUT-02: "+id);

        }
            
        );

    }

    handleFileChange = event => {
        this.setState({
            selectedFile: event.target.files[0],
            loaded: 0,
            message: event.target.files[0] ?
                        event.target.files[0].name : this.state.defaultmessage
        });

    //alert(this.state.selectedFile);
    if(event.target.files[0]){
        this.setState( state => ({showDropzone: false}));
        this.props.showTextInputField(true);
        this.handleUpload(event);
  };
  };

  handleSendId = (id) => {
        //alert("Uploader/handleSendId: "+id);
        this.props.id(id);
    };

  handleUpload = (event) => {

      console.log(event.target.files);
    event.preventDefault();
    if (this.state.uploading) return;
    //alert(event.target.files[0]);
    let okFile = event.target.files[0];
//    if (!this.state.selectedFile) {
//      this.setState({ message: "Select a video first" });
//      return;
//    }
    this.setState({ uploading: true });

    const data = new FormData();
//    data.append('file', this.state.selectedFile, this.state.selectedFile.name);
    data.append('file', okFile, okFile.name);
    //axios.post(endpoint, data).then((res) => {
     // console.log(res.statusText);
    //});
//	let file = this.state.selectedFile;
//	let fileParts = this.state.selectedFile.name.split('.');
	let file = okFile; 
	let fileParts = okFile.name.split('.');
    let fileName = fileParts[0];
    let fileType = fileParts[1];
    axios.post(
		S3_ENDPOINT,
		{ fileName : fileName,
		  fileType : fileType,
		},
		{ onUploadProgress: (ProgressEvent) => {
          console.log(ProgressEvent.loaded,ProgressEvent.total);
          this.setState({
            loaded: (ProgressEvent.loaded / ProgressEvent.total) * 100
          });
        }
		}).then(response => {
		  var returnData = response.data;
		  var signedRequest = returnData.upload_url;
		  var url = returnData.url;
		  this.setState({url: url})
		  console.log("Recieved a signed request " + signedRequest);
                  // Put the fileType in the headers for the upload
                  var options = {
                                headers: {
                                    'Content-Type': fileType
                                },
                      onUploadProgress: (ProgressEvent) => {
                            console.log(this.state.uploadProgress)
                          this.setState({
                              uploadProgress: Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100)
                          })
                          //
                          if(this.state.uploadProgress>=100){
                           //   TweenMax.to(".circular-progress",0.5,{height: 0,display:"block",autoAlpha: 0,marginTop: -35,delay:1});
                          }


                      }
                };
      // Get signed request from S3
      axios.put(signedRequest,file,options)
      .then(result => {
		console.log("Response from S3");
                let id = response.data.id;
                console.log("ID: " + id);
                this.handleSendId(id);
		this.setState(
                    { selectedFile: null,
                      message: "Great! you uploaded your video successfully",
                      uploading: false,
                      id: id,
                    }
                );
                this.setState(state => ({showDropzone: false}));
                let title = "";
                //this.launchPreview(id, title);
                //this.previewAsync(id, title);
                this.launchRender(id, title);
      })
      .catch(error => {
        alert("[ERROR] " + JSON.stringify(error));
      })
    })
    .catch(error => {
		this.setState({ uploading: false, message: "Failed to upload" });
		alert("[ERROR] " + JSON.stringify(error));
    })


    };



  render() {
    const showDropzone = this.state.showDropzone;

    // define if btn select your video
    const btnSelectVideo = showDropzone && (
        <button className={"btn-select-video"} onClick={function(){document.getElementById('file-5').click()}}>Select your video</button>
    )

    const circularProgressbar = !showDropzone && (
        <div className="circular-progress" ref={this.circleProgressRef}>
            <CircularProgressbarWithChildren strokeWidth={"20"} value={this.state.uploadProgress} >
              <div className={"progress-bar-icon"}>
                {this.state.uploadProgress>=100?(<CheckSvg/>):(<UploadArrowSvg/>)}
              </div>
            </CircularProgressbarWithChildren>
        </div>
    )

    // define current progress message
    let currentMessage = false;
    if (this.state.uploading) {
        currentMessage = (<p className={"progress-message"}>Your video<br/> is <span className={"text-highlight"}>uploading</span>!</p>);
    } else {
        if (!this.state.uploading && !showDropzone) {
            currentMessage = (<p className={"progress-message"}>Great!<br/>You uploaded your<br/> video <span className={"text-highlight"}>successfully</span>.</p>);
        }
    }



    return (
    <React.Fragment>

        {btnSelectVideo}
        <div className={"progress-info"}>
            {circularProgressbar}
            <div className={"message-box"}>
                <FadeTransition in={this.state.uploading} timeout={300} >
                    {currentMessage}
                </FadeTransition>
            </div>
        </div>


      <form onSubmit={this.handleUpload}>
        <input
          type="file"
          name="file-5[]"
          id="file-5"
          className="inputfile"
          onChange={this.handleFileChange}
          accept="video/*"
        />
      </form>
    </React.Fragment>
    );
  }
}

export default Uploader;
