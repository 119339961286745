import React, { useState, useEffect } from 'react';
import Tips from '../components/Tips'
import $ from 'jquery'

const TipsPage = ({ match, location, history }) => {

    return (
        <div class={"simple-content first_row"}>
            <h1 class={"title"}>Tips</h1>
            <p className={"sub-title"}>The secret sauce for your best Flipbook</p>
            <div className={"description"}>
                <Tips/>
                <p>
                    A Flipbook is a motion book. So make sure there is movement.<br/>
                    FlipFlip is the perfect way to keep your best moments forever. <br/>
                    It can be your next Mother’s Day gift, wedding invitation or birth announcement.<br/>
                    Because...<br/>
                    <ul>
                        <li>
                            If you FlipFlip your proposal, she will say yes
                        </li>
                        <li>
                            If you FlipFlip your wedding kiss, your mariage will last
                        </li>
                        <li>
                            If you FlipFlip your Kids first step, they will run faster
                        </li>
                        <li>
                            If you FlipFlip your invitation, your friends will show up
                        </li>
                        <li>
                            If you FlipFlip you best moments, they will be with you forever
                        </li>
                    </ul>
                </p>
            </div>
        </div>
    )
}

export default TipsPage;