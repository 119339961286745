import React, {useRef} from 'react';
import { Link } from "react-router-dom";
import TextareaAutosize from 'react-textarea-autosize';
import book_cover from './../media/cover-bg.jpg';







function TitleInputField(props) {

    const textareaRef = useRef();

    const CHARACTER_LIMIT = 50;

    const [values, setValues] = React.useState({
            name: "",
            printlines: "",
            display: "",
    });

    const limitTextarea = (textarea, maxLines, maxChar) => {

        let lineHeight = 24;
        var lines = textarea.value.replace(/\r/g, '').split('\n'), lines_removed, char_removed, i;


        /*console.log( parseInt(textarea.style.height,10) / lineHeight )
        console.log( parseInt(textarea.style.height,10) / lineHeight )
        console.log( maxLines +">="+ ( parseInt(textarea.style.height,10) / lineHeight ) )
        */
        if (maxLines < lines.length ) {
            lines = lines.slice(0, maxLines);
            lines_removed = 1
            if (lines_removed) {
                textarea.value = lines.join('\n')
            }
        }
    }

    React.useEffect(() => {
        textareaRef.current.focus();
    })

    const handleChange = (event) => {

            //let len = event.target.value.length;
            //let title = event.target.value;
            let printlines = event.target.value.replace(/(.{1,17})(?:\n|$| )/g, "$1\n");
            //let display = printlines.split ('\n').map ((item, i) => <div>{item}<br/></div>);
            //alert("test");

        console.log(values);
            setValues({ 'printlines': printlines });
    };





	return (
	    <React.Fragment>
            <div className={"flip-book-conf"}>
                <div className={"conf-container"}>
                    <img className={"conf-book"} src={book_cover} alt="Bookcover without title" style={{width:"100%"}} />
                    <TextareaAutosize maxRows={"12"} cacheMeasurements={true} ref={textareaRef} spellcheck="false" onChange={(e) => {limitTextarea(textareaRef.current,12,999);handleChange(e)}} placeHolder={'"Enter title here"'} className={"conf-title"}>{values.name}</TextareaAutosize>
                </div>
            </div>

            <Link disable={(!props.id || values.printlines==="")&&"disabled"} className={((props.id && values.printlines!=="")&&"is-actived")+" button page-action-button btn-create-flipbook"} to={{ pathname: "/load/"+props.id, state: {id: props.id, title: values.printlines} }}>
                Create your flipbook
            </Link>
        </React.Fragment>
	)

}
export default TitleInputField;
