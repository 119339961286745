import React, { Component,useState, useEffect, useRef } from 'react'
import {ReactComponent as PlayBtnSvg} from "../media/btn-play.svg";
import { TweenLite } from "gsap/all";

const isSafari = () => {
    const ua = navigator.userAgent.toLowerCase();
    return ua.indexOf("safari") > -1 && ua.indexOf("chrome") < 0;
};

const VideoAutoplay = (props) => {

    console.log(props);
    const videoParentRef = useRef();
    const videoBtn = useRef();
    const [shouldUseImage, setShouldUseImage] = useState(false);
    useEffect(() => {
        // check if user agent is safari and we have the ref to the container <div />
        if (isSafari() && videoParentRef.current) {
            // obtain reference to the video element
            const player = videoParentRef.current.children[0];

            // if the reference to video player has been obtained
            if (player) {
                // set the video attributes using javascript as per the
                // webkit Policy
                player.controls = false;
                player.playsinline = true;
                player.muted = true;
                player.setAttribute("muted", ""); // leave no stones unturned :)
                player.autoplay = true;

                // Let's wait for an event loop tick and be async.
                setTimeout(() => {
                    // player.play() might return a promise but it's not guaranteed crossbrowser.
                    const promise = player.play();
                    // let's play safe to ensure that if we do have a promise
                    if (promise.then) {
                        promise
                            .then(() => {})
                            .catch(() => {
                                // if promise fails, hide the video and fallback to <img> tag
                                //videoParentRef.current.style.display = "none";
                                setShouldUseImage(true);
                            });
                    }
                }, 0);
            }
        }
    }, []);


    const playVideo = () => {
        videoParentRef.current.querySelector("video").play();
        TweenLite.to(videoBtn.current, 0.5,{autoAlpha:0,rotate:'-180deg'});
    }

    const playBtn = shouldUseImage && (
        <div ref={videoBtn} className={"player-btn"} onClick={playVideo.bind(this)} >
            {/*<img src={props.src} alt="Muted Video" />*/}
            <PlayBtnSvg/>
        </div>
    )

    return (
        <React.Fragment>

            {playBtn}

            <div

                ref={videoParentRef}
                dangerouslySetInnerHTML={{
                    __html: `
            <video
              loop
              muted
              autoplay
              playsinline
              preload="metadata"
            >
            <source src="${props.src}" type="video/mp4" />
            </video>`
                }}
            />
        </React.Fragment>
    );

}

export default VideoAutoplay;