import React, { useState, useEffect } from 'react';
import $ from 'jquery'

const ImprintPage = ({ match, location, history }) => {

    return (
        <div class={"simple-content"}>
            <h1 class={"title"}>Imprint</h1>
            <p className={"sub-title"}></p>
            <div className={"description"}>

                <h2>The services of Flip Flip are provided by:</h2>
                <p>Flip Flip SARL<br/>
                50 Rue Custine<br/>
                75018 Paris<br/>
                France</p>

                <p>Management:
                Philippe Hadey</p>

                <p>Registry Court: Chambre de Commerce de Paris<br/>
                Immatriculation au RCS, numeéro : 880 176 342 R.C.S. Paris</p>

                <p>Payments for Credit Card are handled via: Stripe</p>

                <p>To resolve online consumer disputes according to Article 14 of Paragraph 1 of the Regulation on Online Dispute Resolution
                    for Consumer Disputes the European Commission provides an online platform: <a href="http://ec.europa.eu/consumers/odr" target="_BLANK">http://ec.europa.eu/consumers/odr</a></p>
            </div>
        </div>
    )
}

export default ImprintPage;