import React, {useEffect, useRef, useState} from 'react';
import $ from 'jquery';
import "./../../node_modules/video-react/dist/video-react.css"; // import css
import { useLocation } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { Link } from "react-router-dom";
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import ReactPolling from 'react-polling';
import {ReactComponent as CheckSvg} from "../media/check.svg";
import {ReactComponent as IconBook} from "../media/icon_book.svg";
import BackgroundCardMobile from "../media/background_form_06.svg";
import BackgroundCardDesktop from "../media/background_form_08.svg";
import ReactCardFlip from 'react-card-flip';
import VideoAutoplay from "../components/VideoAutoplay";
import {TweenMax} from "gsap/all";



const API_PREVIEW_ENDPOINT = "https://hgeo9o8jl5.execute-api.eu-west-3.amazonaws.com/test0/preview"


let timerID = false;

function LoadPage({match}) {

    // set status is-creating-flipbook like body class

    function componentDidMount() { document.title = 'FlipFlip'; };


    const FLP_S3_URL = "https://oinkoink-test.s3.eu-west-3.amazonaws.com/output-video/"
    const isDesktop = useMediaQuery({
        query: '(min-width: 960px)'
    })

    /*const quotes = [
        "Your best moment is ready for Launch"
        ,"3, 2, 1 …. Liftoff"
        ,"Entering the Flip Machine"
        ,"Flip Machine status: Activated"
        ,"The Flip Machine only prints on sustainably forested papers"
        ,"Your moment is now Forever"
        ,"Flip Flip will never run out of Battery"
        ,"Your cover is Unbreakable"
        ,"No need to Update your Flip Flip"
        ,"Flip Flip works Offline only"
        ,"Flip Flip is your time Machine"
        ,"Your best Moment is now forever"
        ,"You’ll never run out of battery"
        ,"From 25Mo to 48 pages"
        ,"No buffering guaranteed"
        ,"Works only offline"
        ,"Even your grandpa will understand how it works"
        ,"We will never release a Flipbook 3,4,5,6,7,8,9 ,X or 11 PRO"
        ,"Our cover is unbreakable ( enfin si tu veux tu peux la défoncer .. )"
        ,"We bet your cloud storage is almost full, what about your library ?"
        ,"It’s a time Machine Marty ! … I mean  Phil"
    ]*/

    const quotes=[
        {
            avatar:"/testimonial_avatar/avatar_02.jpg",
            message:'I did a Flipbook for my wedding,\n' +
                'it works !!! I’m still married ! <span class="emoji">🥳</span>',
            name:"Patrick",
            age:"42"
        },
        {
            avatar:"/testimonial_avatar/avatar_03.jpg",
            message:'I did several flipbooks of my kids first steps, ' +
                'my screen-time on smartphone went down by 60% <span class="emoji">🥳</span>',
            name:"Paula",
            age:"34"
        },
        {
            avatar:"/testimonial_avatar/avatar_04.jpg",
            message:'After 6 month of intense flipping, I am now a master of Thumb wrestling',
            name:"Rocky",
            age:"19"
        },
        {
            avatar:"/testimonial_avatar/avatar_05.jpg",
            message:'100 % of ophthalmologists recommend Flipbooks over Smartphones',
            name:"Dr Eyez",
            age:"67"
        },
        {
            avatar:"/testimonial_avatar/avatar_06.jpg",
            message:'Do you feel me flipping my Flip Flip in my Flip Flops?',
            name:"MC Furtz",
            age:"24"
        },

    ]

    let location = useLocation();
    console.log(location);
    console.log("LoadPage Id: "+location.state.id);
    console.log("LoadPage Title: "+location.state.title);


    const [count, setCount] = useState(0);
    const [i, seti] = useState(1);
    const [frontQuote, setFrontQuote] = useState(quotes[0]);
    const [backQuote, setBackQuote] = useState(quotes[1]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isFlipped, setIsFlipped] = useState(true);


    clearInterval(timerID);
    timerID = setInterval( () => tick(), 5000 );


    useEffect(() => {
        if(!isLoaded) {

            setTimeout(function(){
                $("body").addClass("is-creating-flipbook");
            },10)
        }
    }, []);

    useEffect(() => {
        if(isLoaded){

            $("body").removeClass("is-creating-flipbook");
            clearInterval(timerID);
        }
    }, [isLoaded]);



     function tick(){
         setIsFlipped(!isFlipped);
         setTimeout(()=>{
             if(!isFlipped){
                 setFrontQuote(quotes[count%quotes.length]);
             }else{
                 setBackQuote(quotes[(count)%quotes.length]);
             }
             setCount(count+1);
         },1000)


     };

     function onFailure(error) {
      // You can log this error to some logging service
      // clean up some state and variables.
      console.log(error);
     };

     function onSuccess(response) {
        console.log("onSuccess");
        console.log(response);
        let poll=true;
        if (response.available == true) {
            console.log("AVAILABLE");

            setIsLoaded(true);

            TweenMax.to(".circular-progress",0.5,{height: 0,display:"block",autoAlpha: 0,marginTop: 0,delay:1});

            poll=false;
            $("body").removeClass("is-creating-flipbook");
            };
        // return true to continue polling
        // return false to stop polling


         return poll;

     };

    // init progress bar circle
    const circularProgressbar = (
        <div className="circular-progress">
            <CircularProgressbarWithChildren value={isLoaded?100:count*2} >
                <div className={"progress-bar-icon"}>
                    {isLoaded?(<CheckSvg/>):(<IconBook/>)}
                </div>
            </CircularProgressbarWithChildren>
        </div>
    )

    // define current progress message

    const currentButton = (isLoaded) && (
        <Link  className={"button page-action-button btn-create-order"} to={{
            pathname: "/order/"+match.params.id,
            state: {id: location.state.id, title: location.state.title}
        }}>
            Order
        </Link>)


    const currentMessage = (!isLoaded)?
     (<p className={"progress-message"}>We are making your <span className={"text-highlight"}>Flipbook</span><br/>Please be patient while your FlipFlip is being made.<br/> It can take up to 30 seconds .</p>):
     (<p className={"progress-message"}>Your Flipbook is now<br/> <span className={"text-highlight .success"}>ready</span> !</p>);


    const previewVideo = isLoaded && (
        <div className={"player-video"}>
            <VideoAutoplay src={FLP_S3_URL+match.params.id+".mov"} />
        </div>
    )


    const cards = (!isLoaded) && (
        <ReactCardFlip className="card-container" isFlipped={isFlipped} flipDirection="horizontal">
            <div className={"flip-card flip-card-front"}>
                {getCardQuote(frontQuote)}
                <div class={"card-background"}><img src={isDesktop?BackgroundCardDesktop:BackgroundCardMobile}/></div>
            </div>

            <div className={"flip-card flip-card-back"}>
                {getCardQuote(backQuote)}
                <div className={"card-background"}><img src={isDesktop?BackgroundCardDesktop:BackgroundCardMobile}/></div>
            </div>
        </ReactCardFlip>
    )

    function getCardQuote(p_quote){
        const age = (p_quote.age) && <React.Fragment>, <span className={"age"}>{p_quote.age} years old</span></React.Fragment>;
        const info = (p_quote.name) && <span className={"info"}>{p_quote.name}{age}</span>;
        return (<div className={"box"}>
                    <div className={"avatar"}>
                        <img src={p_quote.avatar} />
                    </div>
                    <div className={"content"}>
                        <p className={"message"} dangerouslySetInnerHTML={{__html: p_quote.message}}></p>
                        {info}
                    </div>
                </div>)
    }


       return(
            <div className={"load-page first_row"}>

                <div className={"progress-info"}>
                    {circularProgressbar}
                    {currentMessage}
                </div>
                <ReactPolling
                    url={API_PREVIEW_ENDPOINT+"?id="+match.params.id}
                    interval= {1000} // in milliseconds(ms)
                    retryCount={3} // this is optional
                    onSuccess={onSuccess}
                    onFailure={onFailure} // this is optional
                    method={'GET'}
                    render={({ startPolling, stopPolling, isPolling }) => {
                        if(isPolling) {
                            return ( null );
                        } else {
                            return ( null );
                        }
                    }}
                />

                {cards}

                { previewVideo }

                {currentButton}



            </div>

       );
};

export default LoadPage;
