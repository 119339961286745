import React from 'react';
import { Link } from "react-router-dom";


function DesktopMenu(props) {

    return (
        <div className={"desktop-menu"}>
            <div className={"menu-content"}>
                <ul>
                    <li>
                        <Link to={""}>SHOP</Link>
                    </li>
                </ul>
                <hr />
                <ul>
                    <li>
                        <Link to={"about-us"}>About us</Link>
                    </li>
                    <li>
                        <Link to={""}>Help</Link>
                    </li>
                    <li>
                        <a href={"mailto:contact@flipflip.io"}>Contact</a>
                    </li>
                </ul>
            </div>
            <div className={"background-overlay"}></div>
        </div>
    );
}
export default DesktopMenu

