import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

const useStyles = makeStyles({
    dashcard: {
        color: "#000033",
        backgroundColor: "transparent",
        borderStyle: "dashed",
        borderRadius: "12px",
        width:"200px",
        height:"200px",
        position: "relative",
    },
    plus: {
		position: "absolute",
        textAlign:"center",
        color: "#142033",
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		width: "50%",
		height: "30%",
		margin: "auto",
	},
});


function Dropzone() {
  const classes = useStyles();
	return (
                <Box>
                    <Card className={classes.dashcard} >
                    <CardContent className={classes.plus}>
                        <Typography  variant='h1'>
                        +
                        </Typography>
                    </CardContent>
                    </Card> 
                </Box>
    )

} 
export default Dropzone;
