import React, { useState, useEffect } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import background_form from './../media/background_form_03.svg'
import { ReactComponent as ArrowRight } from '../media/arrow_right.svg'
import { Grid } from '@material-ui/core';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import CreatePage from "../pages/CreatePage";
import Accordion from "./Accordion";
import { useMediaQuery } from 'react-responsive';



var H1 = { color: "#000033", fontWeight: "900" };

var FaqQ = { fontWeight: "600", paddingTop: "20px" };
var FaqA = {};
var Section = {
    marginTop: "32px",
    marginBottom: "64px",
    marginLeft: "16px",
    marginRight: "16px"
};


const  FAQ = (props) => {

    const qty_display_desktop = (props.qty_desktop )?props.qty_desktop:undefined;
    const qty_display_mobile = (props.qty_mobile )?props.qty_mobile:undefined;

    const [Faq, setFaq] = useState([
        {
            q: "How much do FlipFlip cost?",
            r: 'Each FlipFlip is 12,99€.\nStandard shipping price is 3,70€ (Deutsche Post)'
        },
        {
            q: 'How big are the Flipbooks?',
            r: 'They’re 10,5 by 12,5 cm  (4 by 5 inches).'
        },
        {
            q: ' Have you got any other sizes?',
            r: 'Not for now, hopefully soon!'
        },
        {
            q: 'How long does shipping take?',
            r: 'Usually about a week. In some countries it takes a little longer.\n We ship with the Deutsche Post in Germany  '
        },
        {
            q: 'How do Flipflip work?',
            r: 'By flipping the book’s pages with your thumb, your moment will appear in motion. It’s just like magic; easy & fun.'
        },
        {
            q: 'What video format do you except?',
            r: 'We accept all formats supported by your phone. \nLandscape or Portait mode are accepted.'
        },
        {
            q: 'Can I get my order rush shipped?',
            r: 'Not for now, sorry!'
        },
        {
            q: 'I don’t know what video I should use for my flipbook?',
            r: 'A nice mouvement, a smile, a jump or a slide is enough action to have a magical flipbook.'
        },
        {
            q: 'Can I edit my video in FlipFlip?',
            r: 'Not for now, but you can edit your video directly on your phone. \nGo to Library > choose video > Edit video'
        },
        {
            q: 'Do you ship internationally?',
            r: ' We ship to most countries around the world. \nYou can choose your country from the drop-down menu when you enter your address'
        },

    ]);

    const isDesktop = useMediaQuery({
        query: '(min-width: 960px)'
    })


    const faq_items = () => {
        if (isDesktop) {
            return Faq.slice(0,qty_display_desktop).map((item, i) => {
                return (
                    <div key={i + "faq-item"} className={"box-container"}>
                        <div className={"box"}>
                            <div className={"issue"}>
                                {item.q}
                            </div>
                            <div className={"answer"}>
                                {item.r}
                            </div>
                        </div>
                    </div>
                )
            })
        }else {
            return Faq.slice(0,qty_display_mobile).map((item, i) => {
                return (
                    <div id={"bp-" + i} className={"box-container"} label={item.q}>
                        <p>{item.r}</p>
                    </div>
                )
            })
        }
    }




        return (
            <div className={"faq-component"} >
                {isDesktop ?
                    (<div className={"faq-columns"}>
                            {faq_items()}
                        </div>
                    ):
                    (<Accordion allowMultipleOpen>
                        {faq_items()}
                    </Accordion>)}
            </div>
        )






}

export default FAQ;